import React, { Component } from 'react'
import Projects from './Projects'
import ContactForm from './ContactForm'
import '../assets/scss/Common.scss';

class Body extends Component {
  render() {
    return (
      <div class="body">
        <Projects />
        <ContactForm />
      </div>
    )
  }
}

export default Body;
