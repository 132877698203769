import React, { Component } from 'react'
import Scrollchor from 'react-scrollchor';
import '../assets/scss/Header.scss'
import '../assets/scss/Common.scss';

class HeaderScroll extends Component {
  render() {
    return (
      <div className="header--scroll">
        <div className="header--scroll-container header--intro">
          <h2 className="header--name">Hi, I'm YY</h2>
          <h1 className="header--work">I am a Budding Developer, <br />currently thriving at <a href="http://goodenergy.com.au" target="_blank">Good Energy</a>.</h1>
          <div className="button--wrapper"><Scrollchor to="#projects" className="button">Check out my work</Scrollchor></div>
        </div>
        <div className="header--scroll-container header--story">
          <h2 className="intro--lead">Life is boring without challenges.</h2>
          <p className="intro--story">With a background in psychology and speech pathology, I decided to delve into the unknown world of coding and programming to test the limits of my learning. My need to understand, attend to details and find solutions appeared to be valuable attributes when it comes to creating a website. </p>
        </div>
        <div className="header--scroll-container header--contact" id="projects">
          <p>To learn more about me, venture through my portfolio website. If you like what you're seeing, download my resume and give me a buzz - I'd be more than happy to discuss what we can offer each other.</p>
          <div className="button--wrapper"><Scrollchor to="#contactForm" className="button">Give me a buzz</Scrollchor></div>
        </div>
      </div>
    )
  }
}

export default HeaderScroll;
