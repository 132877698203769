import React, { Component } from 'react'
import '../assets/scss/Body.scss'
import '../assets/scss/Common.scss';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      submitted: false,
      error: false,
      buttonDisabled: true
    };
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(() => this.setState({submitted: true}))
      .catch(error => {
        alert(error)
        this.setState({error: true})
      });

    e.preventDefault();
  };

  handleClick = e => {
    this.setState({submitted: false})
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    if ((this.state.name.length > 1) && (this.state.email.length > 1) && (this.state.message.length > 1)) {
      this.setState({buttonDisabled: false})
    } else {
      this.setState({buttonDisabled: true})
    }
  };

  render() {
    const { name, email, message, submitted, error, buttonDisabled } = this.state;
    return (
      <div className="container--contact" id="contactForm">
        <h1>Contact me</h1>
        { submitted ?
          <div>
            <p> { error ? "Oops, something happened here. Try again or email me: yaeyoungjun@gmail.com" : "Thanks! I'll get back to you shortly" }</p>
            <button onClick={this.handleClick} className="button">{ error ? "Try again" : "Send another form" }</button>
          </div>
        :
          <form class="contact-form" onSubmit={this.handleSubmit}>
            <p class="contact-name">
              <input type="text" name="name" value={name} placeholder="Name*" onChange={this.handleChange} />
            </p>
            <p class="contact-email">
              <input type="email" name="email" value={email} placeholder="Email*" onChange={this.handleChange} />
            </p>
            <p class="contact-message">
              <textarea name="message" value={message} placeholder="Message*" onChange={this.handleChange} />
            </p>
            <p class="contact-button">
              {buttonDisabled ?
                <button type="submit" className="button" disabled>Let's talk</button>
              :
                <button type="submit" className="button">Let's talk</button>
              }

            </p>
          </form>
        }
      </div>
    );
  }

}

export default ContactForm;
