import React, { Component } from 'react'
import Scrollchor from 'react-scrollchor';
import Card from './Card'
import '../assets/scss/Body.scss';
import '../assets/scss/Common.scss';
import pokeapp from '../assets/img/pokeapp.png'
import nc from '../assets/img/mockup-nc.png'
import ns from '../assets/img/mockup-ns.png'

class Projects extends Component {
  render() {
    return (
      <div className="container--projects" id="projects">
        <h1>Projects</h1>
        <div className="cards">
          <Card
            src={ nc }
            alt="natural-chemist"
            overlayTitle="Natural Chemist"
            overlayStack="Javascript(ES6)"
            overlayLink="https://naturalchemist.com.au/"
            color="green"
          />
          <Card
            src={ ns }
            alt="natural script"
            overlayTitle="Natural Script"
            overlayStack="React | Laravel"
            overlayLink="https://naturalchemist.com.au/"
            color="yellow"
          />
          <Card
            src={ pokeapp }
            alt="test"
            overlayTitle="Simple Pokedex"
            overlayStack="React"
            overlayLink="http://yy-pokeapp.herokuapp.com/"
          />
        </div>
        <div className="button--wrapper"><Scrollchor to="#top" className="button">Back to Top</Scrollchor></div>
      </div>
    )
  }
}

export default Projects;
