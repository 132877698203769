import React, { Component } from 'react'
import '../assets/scss/Header.scss'
import '../assets/scss/Common.scss';
import HeaderScroll from './HeaderScroll'
import HeaderStatic from './HeaderStatic'

class Header extends Component {
  render() {
    return (
      <div className="header snap" id="top">
          <HeaderStatic />
          <HeaderScroll />
      </div>

    )
  }
}

export default Header;
