import React, { Component } from 'react'
import '../assets/scss/Card.scss'
import '../assets/scss/Common.scss';

class Card extends Component {
  render() {
    return (

      <div className="card">
        <img
          src={ this.props.src }
          alt={ this.props.alt }
          className={ this.props.color}
        />
        <div className="card-overlay">
          <h3 className="card-title">{ this.props.overlayTitle }</h3>
          <h5 className="card-stack">{ this.props.overlayStack }</h5>
          <a className="card-link" href={ this.props.overlayLink } target="_blank">Visit Website</a>
        </div>
      </div>

    )
  }
}

export default Card;
