import React, { Component } from 'react'
import profile from '../assets/img/yycartoon.png'
import '../assets/scss/Header.scss'
import '../assets/scss/Common.scss';

class HeaderStatic extends Component {
  render() {
    return (
      <div className="header--static">
        <div className="profile-cartoon--wrapper">
          <img src={ profile } alt="profile" className="profile-cartoon" />
        </div>
        <div className="greeting">Hi there!</div>
      </div>
    )
  }
}

export default HeaderStatic;
