import React, { Component } from 'react';
import Header from './Header';
import Body from './Body';
import '../assets/scss/App.scss';
import '../assets/scss/Common.scss';

class App extends Component {
  render() {
    return (
        <div className="App">
          <Header className="snap" />
          <Body className="snap" />
        </div>
    );
  }
}

export default App;
